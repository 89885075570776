import { FormikProps, FormikValues } from 'formik'
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { utmSource } from './queryString'

const LazyGoogleRecaptcha = React.lazy(() => import('./GoogleRecaptcha'))

const enableCaptcha = process.env.GATSBY_TEST_ENV !== 'true' && utmSource() !== 'test-playwright'

type TRecaptcha = {
  reset: () => void
  execute: () => void
}

type RecaptchaContextData = {
  recaptchaRef: React.MutableRefObject<TRecaptcha | undefined> | undefined
  setOnChange: (<T>(onChange: FormikProps<T>['handleSubmit']) => void) | undefined
  onChange: ((e?: React.FormEvent<HTMLFormElement> | undefined) => void) | undefined
}

const GoogleRecaptcha = React.createContext<RecaptchaContextData>({
  recaptchaRef: undefined,
  setOnChange: undefined,
  onChange: undefined,
})

type Props = {
  children: React.ReactNode
}

export const GoogleRecaptchaProvider = <T,>({ children }: Props) => {
  const [isMounted, setIsMounted] = useState(false)
  const ref = useRef<TRecaptcha | undefined>(undefined)
  const [onChange, setOnChange] = useState<FormikProps<T>['handleSubmit']>(() => {})

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <GoogleRecaptcha.Provider value={{ recaptchaRef: ref, onChange, setOnChange }}>
      {isMounted && enableCaptcha ? (
        <Suspense fallback={null}>
          <LazyGoogleRecaptcha />
        </Suspense>
      ) : null}
      {children}
    </GoogleRecaptcha.Provider>
  )
}

export const useRecaptcha = () => useContext(GoogleRecaptcha)

export const useHandleSubmitWithRecaptcha = <T extends FormikValues>(
  handleSubmit: FormikProps<T>['handleSubmit'],
  isValid: FormikProps<T>['isValid'],
) => {
  const { recaptchaRef, setOnChange } = useRecaptcha()

  const handle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('form Handle', { isValid, enableCaptcha, ref: recaptchaRef?.current })
    if (enableCaptcha && recaptchaRef?.current) {
      recaptchaRef?.current?.reset()

      if (!isValid) {
        handleSubmit(event)
      } else {
        setOnChange?.(() => () => {
          handleSubmit(event)
        })

        recaptchaRef?.current?.execute()
      }
    } else {
      handleSubmit(event)
    }
  }

  return handle
}
