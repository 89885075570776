import Grid from '@material-ui/core/Grid'
import parse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'

import Link from '../Link'

interface LegalBlockProps {
  legalText: string
  legalLinks: any
}

const StyledGrid = styled(Grid)`
  width: auto;
  justify-content: flex-start;

  @media (max-width: 840px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 450px) {
    div {
      width: 100%;
      text-align: center;
    }
  }
`

const LegalLink = styled.span`
  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease-in-out;
    padding: 0 0.5rem;
    position: relative;
    left: 1rem;

    &:hover,
    &:focus {
      text-decoration-color: inherit;
      border-bottom: 1px solid #fbfcfc;
      padding-bottom: 2px;
      text-decoration: none;
    }

    &::before {
      content: '|';
      position: absolute;
      left: -1rem;
    }

    @media (max-width: 450px) {
      left: 0;
      &:first-of-type::before {
        content: '';
      }
    }
  }
`

const LegalBlock = (props: LegalBlockProps) => {
  const { legalText, legalLinks } = props

  return (
    <StyledGrid container item style={{ width: 'auto', gap: '1rem' }}>
      <div>{parse(legalText)}</div>
      {legalLinks.map((link: any) => (
        <LegalLink key={link.id}>
          <Link link={link}>{link.text}</Link>
        </LegalLink>
      ))}
    </StyledGrid>
  )
}

export default LegalBlock
