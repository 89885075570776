import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import Link from '../Link'

interface FooterNavProps {
  navigationItems: {
    id: string
    text: string
    menu_items: any[]
  }[]
}

const Nav = styled.nav`
  width: calc(50% - 2rem);
  max-width: 520px;

  @media (max-width: 840px) {
    width: 100%;
    margin-bottom: 48px;
  }
`

const SubList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 16px;

  a {
    color: inherit;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.55;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s ease-in-out;

    &:hover,
    &:focus {
      text-decoration-color: inherit;
      border-bottom: 1px solid #fbfcfc;
      padding-bottom: 2px;
      text-decoration: none;
    }
  }
`

const FooterNav = (props: FooterNavProps) => {
  const { navigationItems } = props

  return (
    <Nav>
      <Grid
        component="ul"
        container
        item
        style={{
          gap: '4.2rem',
          justifyContent: 'flex-start',
          margin: '0',
          padding: '0',
          listStyle: 'none',
          color: rspTheme.palette.primary.contrastText,
        }}
      >
        {navigationItems?.map((item: any) => (
          <li key={item.id} style={{ marginRight: '40px' }}>
            <Typography color="inherit" component="h2" variant="body1">
              {item.text}
            </Typography>
            <SubList>
              {item.menu_items
                .filter((subItem: any) => subItem.text)
                .map((subItem: any) => (
                  <li key={subItem.id}>
                    <Link link={subItem.link?.[0] || subItem}>
                      {subItem.text}
                    </Link>
                  </li>
                ))}
            </SubList>
          </li>
        ))}
      </Grid>
    </Nav>
  )
}

export default FooterNav
