import Grid from '@material-ui/core/Grid'
import React from 'react'
import styled from 'styled-components'

import Link from '../Link'

interface SocialBlockProps {
  socialLinks: any
}

const StyledAnchor = styled.span`
  margin-left: 24px;

  a {
    text-decoration: none;
    color: inherit;

    &:focus {
      outline: 1px solid white;
    }
  }

  @media (max-width: 840px) {
    margin-left: 12px;
    margin-right: 12px;
  }
`

const StyledGrid = styled(Grid)`
  width: auto;

  @media (max-width: 840px) {
    width: 100%;
    margin-top: 28px;
    justify-content: center;
  }
`

const SocialBlock = (props: SocialBlockProps) => {
  const { socialLinks } = props

  return (
    <StyledGrid container>
      {socialLinks.map((link: any) => (
        <StyledAnchor key={link?.id}>
          <Link link={link?.link?.[0]}>
            <>
              <img
                alt={
                  link?.icon_logo?.description || link?.link?.[0]?.title || ''
                }
                height={24}
                loading="lazy"
                src={link?.icon_logo?.imgixImage?.url}
                width={24}
              />
              <span className="sr-only">{link?.link?.[0]?.title}</span>
            </>
          </Link>
        </StyledAnchor>
      ))}
    </StyledGrid>
  )
}

export default SocialBlock
