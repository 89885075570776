import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const FooterWrapper = styled(Box)`
  background-color: ${rspTheme.palette.primary.dark};
  color: white;
  padding: 96px 0 0;
  margin-top: auto;

  @media (max-width: 880px) {
    padding: 48px 0 0;
  }
`

export const BottomStrap = styled(Box)`
  padding: 26px 0 22px;
  border-top: 1px solid rgba(124, 124, 124, 0.54);
  color: rgba(255, 255, 255, 0.54);
  font-size: 1.3rem;
  line-height: 1.5;
  letter-spacing: -0.2px;
`

export const StyledGrid = styled(Grid)`
  width: 100%;
  justify-content: space-between;

  @media (max-width: 880px) {
    justify-content: center;
  }
`

export const HalfWidthColumn = styled.div`
  width: calc(50% - 2rem);

  .contactContainer {
    justify-content: space-between;
  }

  @media (max-width: 880px) {
    width: 100%;

    .contactContainer {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
`

export const LogoBox = styled(Box)`
  margin-bottom: 48px;
`

export const FooterCta = styled.div`
  font-size: 2.1rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 1.45;

  > * {
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s ease-in-out;
    &:hover,
    &:focus {
      text-decoration-color: inherit;
    }
  }

  @media (max-width: 880px) {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
  }
`

export const Separator = styled.div`
  border-right: 1px solid ${rspTheme.palette.primary.background};
  width: 1px;
  height: 40px;

  @media (max-width: 1020px) {
    display: none;
  }
`
