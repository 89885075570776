import Grid from '@material-ui/core/Grid'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { graphql, useStaticQuery } from 'gatsby'
import parse from 'html-react-parser'
import React from 'react'

import Logo from '../NavDesktop/Logo'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import FooterNav from './FooterNav'
import LegalBlock from './Legal'
import Newsletter from './Newsletter'
import SocialBlock from './Social'
import {
  BottomStrap,
  FooterCta,
  FooterWrapper,
  HalfWidthColumn,
  LogoBox,
  Separator,
  StyledGrid,
} from './styles'

const queryClient = new QueryClient()

interface FooterProps {
  locale: string | null
}

const Footer = (props: FooterProps) => {
  const { locale } = props
  const data = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      allContentstackSectionFooter {
        nodes {
          publish_details {
            locale
          }
          legal_links {
            ...NavigationLinkFragment
          }
          legal_text {
            copy
          }
          social_links {
            id
            link {
              ...NavigationLinkFragment
            }
            icon_logo {
              description
              imgixImage {
                url
                gatsbyImageData
              }
            }
          }
          footer_cta_button {
            link {
              ...NavigationLinkFragment
            }
          }
          logo {
            link {
              ...NavigationLinkFragment
            }
            logo {
              description
              imgixImage {
                gatsbyImageData
              }
            }
          }
          navigation_items {
            id
            text
            menu_items {
              ... on Contentstack_component_navigation_item {
                id
                text
                link {
                  ...NavigationLinkFragment
                }
              }
              ... on Contentstack_topic_link {
                ...NavigationLinkFragment
              }
            }
          }
          footer_cta_text
          subscribe {
            headline
            submit_cta {
              copy
            }
            first_name_label {
              copy
            }
            confirmation_message {
              copy
            }
            small_text {
              ... on Contentstack_topic_link {
                ...NavigationLinkFragment
              }
            }
            error_message {
              copy
            }
          }
        }
      }
    }
  `)
  const localizedData = data.allContentstackSectionFooter?.nodes?.filter(
    (node) => node.publish_details?.locale === locale,
  )
  const pageData = localizedData?.[0]
  const logoData = pageData?.logo?.[0]

  return (
    <FooterWrapper component="footer">
      <QueryClientProvider client={queryClient}>
        <Wrapper>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: '32px' }}
          >
            <LogoBox>
              <Logo logoData={logoData} />
            </LogoBox>
            <HalfWidthColumn>
              <Grid
                alignItems="center"
                className="contactContainer"
                container
                justifyContent="space-between"
              >
                <FooterCta>
                  {pageData?.footer_cta_text
                    ? parse(pageData?.footer_cta_text)
                    : null}
                </FooterCta>
                <Separator />
                <LinkCTA
                  link={pageData.footer_cta_button?.[0]?.link?.[0]}
                  variant="secondary"
                >
                  {pageData.footer_cta_button?.[0]?.link?.[0]?.text}
                </LinkCTA>
              </Grid>
            </HalfWidthColumn>
          </Grid>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            style={{ marginBottom: '40px' }}
          >
            <FooterNav navigationItems={pageData?.navigation_items || []} />
            <Newsletter textContent={pageData?.subscribe?.[0] || {}} />
          </Grid>
        </Wrapper>
        <BottomStrap>
          <Wrapper>
            <StyledGrid container>
              <LegalBlock
                legalLinks={pageData?.legal_links || []}
                legalText={pageData?.legal_text?.[0]?.copy || ''}
              />
              <SocialBlock socialLinks={pageData?.social_links || []} />
            </StyledGrid>
          </Wrapper>
        </BottomStrap>
      </QueryClientProvider>
    </FooterWrapper>
  )
}

export default Footer
