import { TextField, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { rspTheme } from '../../styles/rspTheme'
import {
  getBusinessEmailFromLocalStorage,
  useForm,
} from '../../utils/form/useForm'
import { cleanString, useServerSideSafeValue } from '../../utils/helpers'
import { useHandleSubmitWithRecaptcha } from '../../utils/recaptcha'
import GoogleAnalyticsHiddenFields from '../GoogleAnalyticsHiddenFields'
import Link from '../Link'
import Button from '../UI/Button/Button'

interface SubscribeFormProps {
  subscribe: any
  headline: string
}

const StyledForm = styled.form`
  margin: 4px 0 0;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;

  & button[type='submit'] {
    padding: 10px 22px;
  }

  @media (max-width: 500px) {
    justify-content: center;

    & button[type='submit'] {
      margin: 20px auto;
    }
  }
`

const SmallText = styled.p`
  margin: 0;
  text-align: left;
  color: rgba(255, 255, 255, 0.54);
  font-size: ${rspTheme.typography.caption.fontSize};
  letter-spacing: ${rspTheme.typography.caption.letterSpacing};
  line-height: ${rspTheme.typography.caption.lineHeight};

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: 0.3s ease-in-out;

    &:hover,
    &:focus-visible {
      text-decoration-color: inherit;
      border-bottom: 1px solid #fbfcfc;
      padding-bottom: 2px;
      text-decoration: none;
    }
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`

const EmailField = withStyles({
  root: {
    flexGrow: 1,
    minWidth: '300px',
    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: '4px',
      marginBottom: '24px',
    },
    '& .MuiFilledInput-input': {
      padding: '24px 12px 6px',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '& .MuiFormControl-root': {
      position: 'relative',
    },
    '& .MuiFormLabel-root': {
      color: rspTheme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '2px',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0',
      width: '100%',
    },
  },
})(TextField)

const SubscribeForm = (props: SubscribeFormProps) => {
  const { subscribe, headline } = props
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { value: localStorageBusinessEmail, isReady } = useServerSideSafeValue(
    () => getBusinessEmailFromLocalStorage(),
  )

  const initialValues = {
    subscribeEmail: localStorageBusinessEmail ? localStorageBusinessEmail : '',
  }

  const emailError = cleanString(subscribe?.error_message?.[0]?.copy)

  const validationSchema = () =>
    object().shape({
      subscribeEmail: string().required(emailError).email(emailError),
    })

  const formId = 'subscribeForm'

  const form = useForm({
    initialValues,
    formId,
    endpoint: 'newsletter',
    validationSchema,
    onSuccess: () => {
      setIsSubmitted(true)
    },
    mapper: ({ subscribeEmail }) => ({
      email: subscribeEmail,
    }),
    emailField: 'subscribeEmail',
  })

  const handleSubmit = useHandleSubmitWithRecaptcha(
    form.handleSubmit,
    form.isValid,
  )

  useEffect(() => {
    if (isReady) {
      form.setValues(initialValues)
    }
  }, [isReady])

  return (
    <StyledForm data-testid="form" id={formId} onSubmit={handleSubmit}>
      {isSubmitted ? (
        <StyledContainer>
          <Typography
            component="span"
            style={{ marginBottom: '16px' }}
            variant="h2"
          >
            {subscribe?.confirmation_message?.[0]?.copy
              ? cleanString(subscribe?.confirmation_message?.[0]?.copy)
              : null}
          </Typography>
        </StyledContainer>
      ) : (
        <StyledContainer>
          <GoogleAnalyticsHiddenFields />
          <Typography
            component="h2"
            style={{ marginTop: '24px', width: '100%' }}
            variant="body1"
          >
            {headline}
          </Typography>
          <EmailField
            error={
              form.touched.subscribeEmail
                ? Boolean(form.errors.subscribeEmail)
                : null
            }
            helperText={
              form.touched.subscribeEmail ? form.errors.subscribeEmail : null
            }
            id="subscribeEmail"
            label={cleanString(subscribe?.first_name_label?.[0]?.copy)}
            name="subscribeEmail"
            onChange={form.handleChange}
            value={form.values.subscribeEmail}
            variant="filled"
          />
          <Button
            disabled={form.isSubmitting}
            isLoading={form.isSubmitting}
            type="submit"
            variant="ghost"
          >
            {cleanString(subscribe?.submit_cta?.[0]?.copy)}
          </Button>
        </StyledContainer>
      )}
      <SmallText>
        <Link link={subscribe?.small_text?.[0]}>
          {subscribe?.small_text?.[0]?.text}
        </Link>
      </SmallText>
    </StyledForm>
  )
}

export default SubscribeForm
