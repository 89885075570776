import React from 'react'

import { HalfWidthColumn } from './styles'
import SubscribeForm from './SubscribeForm'

interface NewsletterProps {
  textContent: any
}

const Newsletter = (props: NewsletterProps) => {
  const { textContent } = props

  return (
    <HalfWidthColumn>
      <SubscribeForm headline={textContent.headline} subscribe={textContent} />
    </HalfWidthColumn>
  )
}

export default Newsletter
